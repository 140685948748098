<template>
  <el-form ref="editor" class="connector-detail" :model="model" :rules="rules" label-width="140px">
    <el-form-item label="数据源表名称" prop="connectionTableName">
      <el-input v-model="model.connectionTableName" />
    </el-form-item>
    <el-form-item label="表类型" prop="tableType">
      <el-input v-model="model.tableType" />
    </el-form-item>
    <el-form-item label="">
      <el-checkbox v-model="model.isTree" true-label="1" false-label="0">
        树型结构
      </el-checkbox>
    </el-form-item>
    <el-form-item label="树型结构父id">
      <el-input v-model="model.treeParentColumn" />
    </el-form-item>
    <el-form-item label="">
      <el-checkbox v-model="model.isPage" true-label="1" false-label="0">
        开启分页
      </el-checkbox>
    </el-form-item>
    <el-form-item label="表描述" prop="remark">
      <el-input v-model="model.remark" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" size="small" @click="handleSubmit('editor')">
        确认
      </el-button>
      <el-button size="small" @click="handleCancel('editor')">
        取消
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { pick } from 'lodash';
import { resolveDefault } from '@/utils';
import { addDbTable, updateDbTable } from '@/api/finder';

function resolveActionModel (info) {
  const data = pick(info, [
    'id',
    'connection_id',
    'connectionTableName',
    'tableType',
    'isTree',
    'treeParentColumn',
    'isPage',
    'remark'
  ]);

  return resolveDefault({
    action: {},
    extra: {},
    isEnable: true,
    hasChildren: false,
    resourceType: 'menu'
  }, data);
}

export default {
  // inject: ['$resources'],

  props: {
    data: {
      type: Array
    }
  },

  data () {
    return {
      model: {},
      rules: {
        connectionTableName: [
          { required: true, message: '请选输入数据源表名称', trigger: 'blur' }
        ],
        tableType: [
          { required: true, message: '请选输入表类型', trigger: 'blur' }
        ],
        connection_id: [
          { required: true, message: '请选输入数据源ID', trigger: 'blur' }
        ],
        remark: [
          { required: true, message: '请选输表描述', trigger: 'blur' }
        ]
      },
    };
  },

  watch: {
    data: {
      immediate: true,
      handler (val) {
        this.model = resolveActionModel(val[0]);
      }
    }
  },
  methods: {
    handleCancel (formName) {
      this.$refs[formName].resetFields();
      this.$emit('close');
    },
    handleSubmit (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            let params = {
              ...this.model
            };
            if (!this.model.id) {
              params.connectionId = this.$route.query.id;
            }
            const res = await this.$http.post(this.model.id ? updateDbTable : addDbTable, params);
            if (res.code === 0) {
              this.$emit('change', this.model);
              this.$emit('close');
              if (this.model.id === undefined) {
                this.$emit('finder-refresh');
                this.$message.success('创建成功');
              } else {
                this.$message.success('更新成功');
              }
            } else {
              this.$message.error(res.msg);
            }
          } catch (err) {
            console.log('error', err);
          }
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.connector-detail {
  padding: 10px 20px;
}
</style>
